import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Mygallery from "../components/pictures/mygallery"
import Header from "../components/pictures/header"

const Pictures = () => {
  return (
    <Layout pageTitle="Pictures - Forest Grove Montessori Pre-School">
      <Header />
      <Mygallery />
    </Layout>
  )
}

export default Pictures
