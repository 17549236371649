import * as React from "react"
import "photoswipe/dist/photoswipe.css"
import { Gallery, Item } from "react-photoswipe-gallery"

const Mygallery = () => {
  const images = [
    "https://i.ibb.co/XYRCPYh/IMG-0034.jpg",
    "https://i.ibb.co/zFr2Ldb/IMG-0033.jpg",
    "https://i.ibb.co/N7N8KWT/IMG-0032.jpg",
    "https://i.ibb.co/cQQWBdB/IMG-0031.jpg",
    "https://i.ibb.co/HgbHcLx/IMG-0030.jpg",
    "https://i.ibb.co/j8073FV/IMG-0029.jpg",
    "https://i.ibb.co/7GLDRzC/IMG-0028.jpg",
    "https://i.ibb.co/9pykTWF/IMG-0027.jpg",
    "https://i.ibb.co/BspYcdy/IMG-0026.jpg",
    "https://i.ibb.co/3RgdVC2/IMG-0025.jpg",
    "https://i.ibb.co/zXsmfVT/IMG-0024.jpg",
    "https://i.ibb.co/34TZKcS/IMG-0023.jpg",
    "https://i.ibb.co/CmNST2N/IMG-0022.jpg",
    "https://i.ibb.co/jvwHggW/IMG-0021.jpg",
    "https://i.ibb.co/64GFLPd/IMG-0020.jpg",
    "https://i.ibb.co/xmQxj6Q/IMG-0019.jpg",
    "https://i.ibb.co/jzfKGZn/IMG-0018.jpg",
    "https://i.ibb.co/CW3h1RZ/IMG-0017.jpg",
    "https://i.ibb.co/y64wvBQ/IMG-0015.jpg",
    "https://i.ibb.co/GFbYmR0/IMG-0014.jpg",
    "https://i.ibb.co/KG8kt7C/IMG-0013.jpg",
    "https://i.ibb.co/QjdtVGY/IMG-0012.jpg",
    "https://i.ibb.co/7VDmW6m/IMG-0011.jpg",
    "https://i.ibb.co/F7FxYmb/IMG-0010.jpg",
    "https://i.ibb.co/wChFSFL/IMG-0009.jpg",
    "https://i.ibb.co/0mwBjm8/IMG-0008.jpg",
    "https://i.ibb.co/5WfTdSm/IMG-0007.jpg",
    "https://i.ibb.co/gZG5hqT/IMG-0006.jpg",
    "https://i.ibb.co/QmCXR0J/IMG-0005.jpg",
    "https://i.ibb.co/mSjF7D5/IMG-0004.jpg",
    "https://i.ibb.co/4KLcgX1/bitmap.png",
    "https://i.ibb.co/6FSFFwd/IMG-0003.jpg",
    "https://i.ibb.co/zQPgkKg/IMG-0002.jpg",
    "https://i.ibb.co/jZGCYg1/IMG-0001.jpg",
  ]

  // reverse the images array
  const imagesReversed = images.reverse()

  return (
    <div className="max-w-screen-xl mx-auto py-12 lg:py-24">
      <div className="px-4">
        <h2 className="text-greenDark text-3xl text-center font-light font-quick tracking-tight">
          Image Gallery
        </h2>
        <div className="mt-10 lg:mt-14">
          <Gallery>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-2 md:space-y-0 cursor-pointer">
              {imagesReversed.map((image) => (
                <Item
                  key={image.key}
                  original={image}
                  thumbnail={image}
                  width="1160"
                  height="750"
                >
                  {({ ref, open }) => (
                    <img ref={ref} onClick={open} src={image} alt="Photos" />
                  )}
                </Item>
              ))}
            </div>
          </Gallery>
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default Mygallery
